import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/Layout';

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <div>
      <h1>404 Not Found</h1>
      <p>Unfortunately the content you are looking for doesn't seem to be here. Try again using the navigation to the left, or email support@carbondmp.com for help.</p>
    </div>
  </Layout>
);

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired
};

export default NotFoundPage;
